@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.tagContainer {
    border-radius: 10px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content;

    &.negative {
        border: 1px solid $accents-red-100;
        background: $accents-red-20;
        color: $accents-red-text;
    }

    &.ready {
        border: 1px solid $accents-spark-purple-100;
        background: $accents-spark-purple-20;
        color: $accents-spark-purple-text;
    }

    &.paused {
        border: 1px solid $accents-yellow-100;
        background: $accents-yellow-20;
        color: $accents-yellow-text;
    }

    &.caution {
        border: 1px solid $accents-orange-100;
        background: $accents-orange-20;
        color: $accents-orange-text;
    }

    &.inProgress {
        border: 1px solid $accents-spark-green-100;
        background: $accents-spark-green-20;
        color: $accents-spark-green-text;
    }

    &.done {
        border: 1px solid $accents-green-100;
        background: $accents-green-20;
        color: $accents-green-text;
    }

    &.testing {
        border: 1px solid $accents-light-blue-100;
        background: $accents-light-blue-20;
        color: $accents-light-blue-text;
    }

    &.natural {
        border: 1px solid $surfaces-surface2;
        background: $gray-gray40;
        color: $icon-iconcolorsecondary;
    }

    &.white {
        background: $neutral-white;
        color: $icon-iconcolorsecondary;
    }
}