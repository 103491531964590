@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.selector-container {
    padding: 4px 12px;
    gap: 8px;
    border-radius: 8px;
    background: $surfaces-backgroundcolor;
    color: $text-secondarytextcolor;

    &:hover {
        background: $surfaces-surface1;
    }

    &.active {
        background: $white;

        &:hover {
            background: $surfaces-surface1;
        }
    }

    &.non-editable {
        background: $white;
        color: $icon-iconcolor;
    }
}