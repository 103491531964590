@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.text-with-action {
    border-radius: 16px;
    border: 1px solid $surfaces-surface2;
    background: $white;
    gap: 12px;
    padding: 12px;
    width: 100%;
    .title {
        color: $text-textcolor;
    }
}