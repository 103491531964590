@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.initiative-management {
    font-family: "Inter";
    // @notice should be seated in the mainLayout
    background-color: $surfaces-backgroundcolor;
    // height: 100vh;
    position: relative;
    // padding: 0px 20px;

    .initiative-management-content{
        // gap: 8px;
        padding: 8px 0px;
        overflow: hidden;
        align-items: center;
    }

    .initiative-management-content-main-container{
        padding: 12px 8px;
        max-height: calc(100vh - 68px);
        overflow-y: auto;
    }

    .initiative-management-content-main {
        max-width: 720px;
        gap: 8px;
    }

    .initiative-management-assistant-panel {
        width: 496px;
        min-width: 300px;
        padding: 8px;
        border-radius: 16px;
        border: 1px solid $surfaces-surface2;
        background: $white;
        margin: 8px;
        height: calc(100vh - 16px);
        overflow-y: hidden;
        // flex-shrink: 0;
    }

    .initiative-editor-container {
        width: 100%;

        :global {
            .tox    {
                    &:not(.tox-tinymce-inline).tox-tinymce--toolbar-bottom .tox-editor-header {
                        border-top: none;
                        box-shadow: none;
                        border: none;
                    }

                    .tox-editor-header {
                        width: 100%;
                        height: inherit;
                        position: fixed;
                        top: 64px;
                        // Calculate positions based on the sidebar & heading width
                        left: calc(var(--initiative-sidebar-width) + 32px);
                        width: calc(var(--initiative-heading-width) - 32px);

                        background-color: #fff;
                        z-index: 1000;
                        display: flex;
                        align-items: center;

                        box-shadow: none;
                        border: none;
                        border-top: none;
                        padding: 0;
                        overflow: hidden;
                        border-radius: 14px;

                        .tox-toolbar, .tox-toolbar__overflow,  .tox-toolbar__primary{
                            background-image: none;
                            width: 100%;
                        }

                        .tox-toolbar-overlord {
                            display: flex;
                            width: 100%;
                            padding: 0;
                            align-items: center;
                            justify-content: center;
                            padding: 4px 4px 6px 4px;
                            border-radius: 14px;
                            border: 1px solid $surfaces-surface2;
                            background: $white;
                            box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.04) inset;
                            overflow: hidden;

                            .tox-toolbar__group {
                                display: flex;
                                align-items: center;
                                // justify-content: space-around;
                                width: 100%;
                                padding: 0;
                                gap: 4px;

                                .tox-tbtn--bespoke {
                                    border-radius: 12px;
                                    background: $surfaces-surface1;
                                    width: 156px;
                                    height: 36px;
                                    padding: 8px 12px;
                                }
                            }
                        }
                    }
                }
        }
        color: $text-secondary;
    }

    .empty-view {
        height: 340px;
        padding: 12px;
        gap: 16px;
        background: $surfaces-backgroundcolor;

        .empty-view-content {
            max-width: 350px;
            gap: 16px;

            .title {
                color: $text-textcolor;
                text-align: center;
            }

            .description {
                color: $text-secondarytextcolor;
                text-align: center;
            }

            .manual-action {
                color: $text-tertiarytextcolor;
                cursor: pointer;

                :hover {
                    color: $text-textcolor;
                }

                p {
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-decoration-skip-ink: none;
                    text-decoration-thickness: auto;
                    text-underline-offset: auto;
                }
            }
        }
    }
}