@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.mockups-container {
    gap: 12px;
    border-radius: 16px;
    border: 1px solid $surfaces-surface2;
    background: $white;
    padding: 12px;

    .mockups-container-header {
        gap: 10px;

        .section-title {
            color: $text-color;
        }
    }

    .mockups-container-body {
        padding: 8px;
        border-radius: 16px;
        background: $surfaces-backgroundcolor;
        // temporary
        width: 100%;
        height: 400px;
    }
}