@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

//________________________________Colors - Light Mode
$brand-green300: rgba(2, 74, 80, 1);
$gray-gray100: rgba(47, 50, 55, 1);
$brand-green200: rgba(0, 112, 75, 1);
$gray-gray90: rgba(92, 94, 99, 1);
$gray-gray80: rgba(117, 119, 124, 1);
$gray-gray70: rgba(218, 218, 221, 1);
$gray-gray60: rgba(238, 239, 241, 1);
$gray-gray50: rgba(244, 245, 246, 1);
$gray-gray40: rgba(249, 250, 250, 1);
$gray-gray30: rgba(250, 250, 250, 1);
$brand-green100: rgba(0, 122, 82, 1);
$brand-green90: rgba(0, 133, 88, 1);
$brand-green80: rgba(0, 184, 121, 1);
$brand-green70: rgba(204, 235, 224, 1);
$brand-green60: rgba(217, 242, 234, 1);
$brand-green50: rgba(233, 247, 242, 1);
$brand-green40: rgba(241, 249, 246, 1);
$brand-light-green100: rgba(214, 224, 102, 1);
$accents-red-20: rgba(255, 235, 235, 1);
$accents-red-100: #FFA3AC;
$accents-red: rgba(255, 121, 134, 1);
$accents-red-text: #FF4255;
$accents-red-orange-20: #FFDBDB;
$accents-red-orange-100: rgba(254, 224, 200, 1);
$accents-red-orange-text: rgba(122, 60, 11, 1);
$accents-orange-20: rgba(255, 243, 204, 1);
$accents-orange-100: rgba(255, 236, 179, 1);
$accents-orange: rgba(255, 187, 51, 1);
$accents-orange-text: rgba(112, 85, 0, 1);
$accents-yellow-20: rgba(253, 247, 196, 1);
$accents-yellow-100: rgba(251, 241, 151, 1);
$accents-yellow-text: rgba(115, 89, 8, 1);
$accents-spark-green-20: rgba(244, 251, 203, 1);
$accents-spark-green-100: rgba(239, 249, 181, 1);
$accents-spark-green: rgba(214, 224, 102, 1);
$accents-spark-green-text: rgba(80, 95, 7, 1);
$accents-green-20: rgba(221, 249, 227, 1);
$accents-green-100: rgba(199, 244, 210, 1);
$accents-green: rgba(51, 204, 178, 1);
$accents-green-text: rgba(8, 90, 57, 1);
$accents-light-blue-20: rgba(218, 244, 252, 1);
$accents-light-blue-100: rgba(197, 237, 249, 1);
$accents-light-blue: rgba(51, 169, 255, 1);
$accents-light-blue-text: rgba(8, 90, 112, 1);
$accents-light-purple-20: rgba(229, 238, 255, 1);
$accents-light-purple-100: rgba(214, 228, 255, 1);
$accents-light-purple: rgba(130, 128, 255, 1);
$accents-light-purple-text: rgba(23, 60, 128, 1);
$accents-spark-purple-20: rgba(245, 240, 255, 1);
$accents-spark-purple-100: rgba(232, 221, 254, 1);
$accents-spark-purple: rgba(76, 61, 245, 1);
$accents-spark-purple-text: rgba(82, 32, 191, 1);
$accents-pink-purple-20: rgba(247, 230, 249, 1);
$accents-pink-purple-100: rgba(242, 214, 245, 1);
$accents-pink-purple-text: rgba(105, 27, 116, 1);
$neutral-white: rgba(255, 255, 255, 1);
$neutral-white-60: rgba(255, 255, 255, 0.6000000238418579);
$neutral-white-20: rgba(255, 255, 255, 0.4000000059604645);
$neutral-white-10: rgba(255, 255, 255, 0.10000000149011612);
$neutral-black: rgba(0, 0, 0, 1);
$accent-caution20: rgba(255, 187, 51, 0.20000000298023224);
$accent-high10: rgba(255, 148, 41, 0.10000000149011612);
$accent-link: rgba(76, 61, 245, 1);
$accent-link-10: rgba(76, 61, 245, 0.10000000149011612);
$accent-caution: rgba(255, 187, 51, 1);
$accent-critical10: rgba(255, 121, 134, 0.10000000149011612);
$accent-high: rgba(255, 148, 41, 1);
$accent-success20: rgba(85, 220, 200, 0.20000000298023224);
$accent-low: rgba(130, 128, 255, 1);
$accent-low10: rgba(55, 51, 255, 0.10000000149011612);
$accent-medium: rgba(51, 169, 255, 1);
$accent-medium-10: rgba(51, 169, 255, 0.10000000149011612);
$accent-success: rgba(51, 204, 178, 1);
$accent-critical: rgba(255, 121, 134, 1);

//________________________________Tokens - Light Mode
$text-primarytextcolor: $brand-green200;
$brand-primarycolor: $brand-green100;
$brand-primarylightcolor: $brand-green90;
$brand-primarydarkcolor: $brand-green200;
$brand-secondarycolor: $accents-spark-green;
$text-textcolor: $gray-gray100;
$text-secondarytextcolor: $gray-gray90;
$text-tertiarytextcolor: $gray-gray80;
$text-textonimage: $neutral-white;
$text-textonprimary: $brand-green60;
$surfaces-surface-4: $gray-gray80;
$surfaces-surface-3: $gray-gray70;
$surfaces-surface2: $gray-gray60;
$surfaces-surface1: $gray-gray50;
$surfaces-backgroundcolor: $gray-gray40;
$icon-iconcolor: $gray-gray100;
$icon-iconcolorsecondary: $gray-gray90;
$icon-iconcolortertiary: $gray-gray80;
$accent-success: rgba(51, 204, 178, 1);
$accent-success20: rgba(85, 220, 200, 0.20000000298023224);
$accent-critical: rgba(255, 121, 134, 1);
$accent-critical10: rgba(255, 121, 134, 0.10000000149011612);
$accent-caution: rgba(255, 187, 51, 1);
$accent-caution20: rgba(255, 187, 51, 0.20000000298023224);
$accent-link: rgba(76, 61, 245, 1);
$accent-link-10: rgba(76, 61, 245, 0.10000000149011612);
$accent-high: rgba(255, 148, 41, 1);
$accent-high10: rgba(255, 148, 41, 0.10000000149011612);
$accent-low: rgba(130, 128, 255, 1);
$accent-low10: rgba(55, 51, 255, 0.10000000149011612);
$accent-medium: rgba(51, 169, 255, 1);
$accent-medium-10: rgba(51, 169, 255, 0.10000000149011612);


// Shadows_____________________________________
/* long */
$shadow-long: 1px 5px 12px -4px rgba(140, 130, 154, 0.10);
/* small soft */
$shadow-small-soft: 0px 3px 2px -2px rgba(109, 109, 141, 0.06);
/* simple button shadow */
$shadow-simple-button: 0px 1px 1px -3px rgba(109, 109, 141, 0.06);
/* akhooekhto */
$shadow-akhooekhto: 1px 5px 5px -5px rgba(140, 130, 154, 0.10);
/* big shadow */
$shadow-big: -8px 2px 20px 0px rgba(109, 109, 141, 0.06);

// Colors_____________________________________
// Surfaces
$surface1: #F4F5F6;
$surface2: #EBEDEF;

// Texts
$text-secondary: #5C5E63;
$text-color: #2F3237;

// Gray/Accents/Green Text
$gray-accents-green-text: #085A39;
$gray-accents-green-100: #C7F4D2;
$gray-accents-green-20: #DDF9E3;
$gray-accents-spark-green-100: #EFF9B5;
$gray-accents-light-blue-100: #C5EDF9;
$gray-accents-red-orange-100: #FEE0C8;
$gray-accents-light-purple-100: #D6E4FF;


$white: #FFF;

// Borders
$border-surface2: 1px solid $gray-gray60;

// Backgrounds
$background-white: $white;

// Fonts
$font-family: 'Inter';