@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.initiative-sidePanel {
    width: 288px;
    height: calc(100vh - 16px);
    padding: 8px;
    overflow-y: hidden;
    margin: 8px;
    border-radius: 12px;
    border: 1px solid $surfaces-surface2;
    background: $white;

    .title {
        color: $text-textcolor;
        font-family: "DM Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .navigation {
        padding: 12px 0px;
        gap: 12px;

        .navigation-main {
            padding-bottom: 8px;
            gap: 8px;
            border-bottom: 1px solid $surfaces-surface2;

            .navigation-item {
                padding: 8px 12px;
                gap: 8px;
                border-radius: 12px;
                color: $text-textcolor;
                cursor: pointer;

                &:hover {
                    background: $surfaces-surface1;
                }

                &.active {
                    background: $brand-green40;
                    color: $brand-primarycolor;
                    .navigation-item-title {
                        color: $brand-primarycolor;
                    }
                }

                &.new {
                    opacity: 0.5;
                }
            }

            .navigation-item-icon {
                width: 20px;
                height: 20px;
            }

            .navigation-item-title {
                overflow: hidden;
                color: $text-textcolor;
                text-overflow: ellipsis;
            }
        }
    }

    .initiative-sidePanel-score {
        border-radius: 16px;
        border: 1px solid $surfaces-surface2;
        background: $surfaces-backgroundcolor;
        width: 100%;
        padding: 4px;
        gap: 10px;


        .initiative-sidePanel-score-content {
            border-radius: 14px;
            border: 1px solid $surfaces-surface2;
            background: $white;
            box-shadow: $shadow-akhooekhto;
            padding: 4px 8px 4px 4px;
        }

        .scoreBox-container {
            gap: 8px;

            .score {
                padding: 8px 7px 8px 8px;
                border-radius: 10px;
                background: $brand-green80;
                color: $white;
                font-family: "Inter";
                font-size: 18px;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: -0.18px;
            }

            .score-text-title {
                color: $text-secondarytextcolor;
            }

            .score-text-description {
                color: $text-primarytextcolor;
            }

            .score-icon {
                color: $icon-iconcolor;
                width: 20px;
                height: 20px;
            }
        }
    }

    .add-new-section {
        width: fit-content;
    }
}