@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.sidePanel-input {
    border-radius: 16px;
    border: 1px solid $surfaces-surface2;
    background: $surfaces-surface1;
    padding: 8px 4px;
    gap: 8px;

    .input-container {
        border-radius: 16px;
        border: 1px solid $surfaces-surface2;
        background: $neutral-white;
        padding: 6px 6px 6px 16px;
        gap: 8px;
        cursor: text;

        &:hover {
            border: 1px solid $surfaces-surface-3;
            opacity: 0.8;
        }

        :global {
            .MuiInputBase-root{
                padding: 0;
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.16px;
                flex: 1;

            }

            :placeholder {
                color: 0.3;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .arrow-right-icon-button {
            background-color: $surfaces-surface-4;
            border-radius: 40px;

            &:hover {
                background-color: $gray-gray90;
            }
        }
    }

    .description {
        color: $text-secondarytextcolor;
        text-align: center;
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}
